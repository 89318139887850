import React, {useState, useEffect} from 'react';
import { browserHistory, Link } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper,  TextField, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { createEngine } from '../';
import { helperFunc } from '../helperFunc';
import EngineWorkOrder from '../containers/EngineWorkOrder';
const AddEngineWorkOrder = ({params}) => {
     const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [engine, setEngine] = useState(createEngine);
    const [enginesList, setEnginesList] = useState([]);
    const [engineTypes, setEngineTypes] = useState([]);
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [forms, setForms] = useState([]);
    const [pltConstants, setPltConstants] = useState([]);
    const [error, setError] = useState({});
    const [tabIndex, setTabIndex] = useState('engine-current-details')
    useEffect(() => {
        getEngineForms();
        getPlatformConstants();
        getAircraftTypes();
        getEngines();
        getEngineTypes();
        if(params?.workOrderSlug){fetchEngineWorkOrderDetails()}
    },[params])
    useEffect(() =>  {
        if(engine?.location) getLocationDetails(engine?.location);
      }, [engine?.location])
    const getEngineTypes = () => {helperFunc.fetchApi("console/engine-types/", "get", {}, (data) => {setEngineTypes(data?.responseData?.engineTypes);});}
    const getAircraftTypes = () => {helperFunc.fetchApi("console/aircraft-types/", "get", {}, (data) => {setAircraftTypes(data?.responseData?.aircraftTypes);});}
    const getEngineForms = () => {helperFunc.fetchApi("technical/forms/", "get", { form_type: 2 }, (data) => {setForms(data?.responseData);});}
    const getEngines = () => {helperFunc.fetchApi(`console/engine-esn-list/`, "get",{ switcher:true}, (data) => {setLoading(true);setEnginesList(data?.responseData?.engines);setLoading(false)});}
    const getPlatformConstants = () => {helperFunc.fetchApi("console/get-constants/","post",{ constant_types: ["engine_position"] },
            (data) => {
              const updatedConstant =data?.responseData?.length>0 && data?.responseData?.map((constant) => ({...constant,id: constant?.value,name: constant?.label,}));
              setPltConstants(updatedConstant);
            }
          );
    }
    const fetchEngineWorkOrderDetails = ()=>{
        setLoading(true);
        helperFunc.fetchApi(`/technical/project/${params.project_slug}/engine-workorder/${params.workOrderSlug}/`,"get",{},(data) => {
            setEngine({...data?.responseData, 
                tsn: String(data?.responseData?.tsn) || "",
                csn: String(data?.responseData?.csn) || "",
                mr: String(data?.responseData?.mr) ||"",
                end_of_lease :String(data?.responseData?.end_of_lease)|| "",
                lease_contract :  String(data?.responseData?.lease_contract) || "" ,
                qme :  String(data?.responseData?.qme) || ""
            }); 
            })
    }
    const getEngineDetails = (slug) => {
        setLoading(true);
        helperFunc.fetchApi(`technical/engine/${slug}/details/`, "get", {}, (data) => {
            if (data?.responseData && Object.keys(data?.responseData).length > 0) {
            setLoading(false);
            setEngine({
                ...engine,
                ...data?.responseData,
                create_esn: data?.responseData?.create_esn ? data?.responseData?.create_esn : createEngine.create_esn,
                tsn: String(data?.responseData?.tsn || ""),
                csn: String(data?.responseData?.csn || ""),
              });
            } 
          },(error)=> {enqueueSnackbar(error, {variant: "error",anchorOrigin: { horizontal: "right", vertical: "top" },});  setLoading(false);});
    }
    const onFieldChange = (key, value) => {
        setError({})
        if (key === "engine_id" && engine?.create_esn === false) {
             setEngine({ ...engine,esn : value?.esn, engine_id : value?.id });
             if(value?.slug) getEngineDetails(value?.slug);
        } else setEngine({ ...engine, [key]: value });
    }
    const getLocationDetails =async (address)=>{
        try {
          const results = await geocodeByAddress(address);
          const countryComponent = results[0]?.address_components.find((component) =>
            component.types.includes("country")
          );
          const countryName = countryComponent ? countryComponent.long_name : "Unknown Country";
          const latLng = await getLatLng(results[0]);
          setEngine({...engine,country :countryName, lat : latLng.lat,lang : latLng.lng });
        } catch (error) {
          console.error("Error fetching coordinates or country:", error);
        }
      }
    const onAddEngine = () => {
        setButtonLoading(true)
        let validationInputs = {
            esn:engine?.esn?.trim()?.length ? '':'Please enter Serial Number',
            engine_type_id:engine?.engine_type_id ? '':'Please select Engine Type',
            country:engine.create_esn ? engine?.country?.trim()?.length ? '':'Please enter Country':'',
            engine_manufacturing_date:engine?.engine_manufacturing_date ? '':'Please enter Manufacturing Date',
            tsn: engine?.tsn === 0 ||engine?.tsn?.trim()?.length ? '':'Please enter TSN',
            csn:engine?.csn === 0 ||engine?.csn?.trim()?.length  ? '':'Please enter CSN',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            let payload = {
                ...engine,
                qme:engine?.qme ?  Number(engine?.qme) : null,
                mr: engine?.mr ? Number(engine?.mr) : null,
                lease_contract:engine?.lease_contract ?  Number(engine?.lease_contract) : null,
                end_of_lease:engine?.end_of_lease ? Number(engine?.end_of_lease) : null,
            }
            let apiUrl
            if(params?.workOrderSlug){
                apiUrl = `engine-workorder/${params.workOrderSlug}`
            }else{
                if(engine?.create_esn === false){delete payload.create_esn;}
                apiUrl = `create-engine-workorder`
            }
            helperFunc.fetchApi(`/technical/project/${params.project_slug}/${apiUrl}/`, params?.workOrderSlug ? "put" : "post", { data: payload }, (data) => {
                setButtonLoading(false)
                enqueueSnackbar(data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                browserHistory.push('/technical/project/view/' + params.project_slug);
            }, (error) => { enqueueSnackbar(error, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });setButtonLoading(false); setEngine({ ...engine, create_esn: false }) }
            );
        }else{
            setButtonLoading(false)
            setError(validationInputs);
            setTabIndex("engine-current-details")
        }
      }

    const handleMultiDelete = (index,componentKey) => {
        const updatedRows = [...engine[componentKey]];
        updatedRows.splice(index, 1);
        setEngine({ ...engine, [componentKey]: updatedRows });
    }
    const onMultiFieldChange = (index, field, value,componentKey) => {
        const updatedRows = [...engine[componentKey]];
        updatedRows[index][field] = value;
        setEngine({ ...engine, [componentKey]: updatedRows })
    };
    const filteredOptions = forms?.filter(
        (option) => !engine?.forms.some((selected) => selected.id === option.id)
      );
    return(
            <div className='project-management-modal add-edit-work-order-modal' style={{marginLeft:'34px',padding:' 0px 0px 0px 30px',position:'',}}>
            <Link style={{ cursor: 'pointer', width: "110px", marginTop: "10px" }} className='flex-centered' to={"/technical/project/view/" + params.project_slug}>
                <ArrowBackIcon fontSize='medium' color='primary' /> <h5 style={{ color: '#3f51b5' }}>{params?.workOrderSlug ? "Edit ESN" : "Add ESN"}</h5>
            </Link>
                <Grid container spacing={1}>
                    <Grid item md={2}></Grid>
                </Grid>
                <Grid container spacing={1} style={{position: ''}}>
                    {params?.workOrderSlug ?null  : <Grid item md={12}>
                        <RadioGroup row aria-label="position" name="position" defaultValue="link">
                            <FormControlLabel value="link" control={<Radio checked={engine.create_esn === false} onChange={() =>{setTabIndex("engine-current-details"); setEngine({...createEngine, create_esn:false})}} color="primary"  size='small'/>} label="Link Existing ESN" />
                            <FormControlLabel value="create" control={<Radio checked={engine.create_esn === true} onChange={() =>{setTabIndex("engine-current-details"); setEngine({...createEngine, create_esn:true})}} color="primary" size='small' />} label="Create New ESN" />
                        </RadioGroup>
                    </Grid>}
                    <Grid item md={12} style={{marginBottom:"10px"}}>
                        <Autocomplete
                            multiple
                            options = {filteredOptions}
                            getOptionLabel={option => option.name}
                            name="forms"
                            value={engine?.forms||[]}
                            onChange={(e,value) => onFieldChange('forms', value)}
                            renderInput={params => <TextField {...params} label="Forms" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                        />
                    </Grid>
                    </Grid>
                    <Paper>
                       <EngineWorkOrder tabIndex={tabIndex} setTabIndex={setTabIndex}  params={params} engine={engine} setEngine={setEngine} handleMultiDelete={handleMultiDelete} onMultiFieldChange={onMultiFieldChange} error={error} onAddEngine={onAddEngine} enginesList={enginesList} engineTypes={engineTypes} aircraftTypes={aircraftTypes} pltConstants={pltConstants} onFieldChange={onFieldChange} isLoading={isLoading} isButtonLoading={isButtonLoading}/>
                    </Paper>
            </div>
    )
}
export default AddEngineWorkOrder;
