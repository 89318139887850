import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import PlacesAutocomplete from "react-places-autocomplete";
const LocationField = ({ location ,onFieldChange}) => {
  const [address, setAddress] = useState(location ? location : ""); 
  useEffect(() => {
    if (location) setAddress(location);
    else setAddress("");
  }, [location]);
  const handleSelect = (selectedAddress) => {
    setAddress(selectedAddress);
    onFieldChange("", "location", selectedAddress);
  };
  return (
    <div style={{position:"relative"}}>
      <PlacesAutocomplete
        value={address} 
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              id="location"
              label={'Location'}
              fullWidth
              margin="normal"
              value={''}
              InputLabelProps={{ shrink: true }}
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
              variant='outlined'
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { className, style })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

    </div>
  );
};

export default LocationField;