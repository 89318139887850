import moment from 'moment';
import React from 'react'
import { displayDateFormat, imgStoragePath } from '../../../constants';
import "./../assets/assetPopover.scss"
const AssetPopover = ({ assetDetail }) => {
  const startDate = assetDetail?.clickedAsset?.start_date ? moment(assetDetail?.clickedAsset?.start_date).format(displayDateFormat) : '--';
  const endDate = assetDetail?.clickedAsset?.end_date ? moment(assetDetail?.clickedAsset?.end_date).format(displayDateFormat) : '--';
  const statusLabel = assetDetail?.clickedAsset?.status?.label || '';
  return (
    <div className="asset-detail-card">
      <div className="asset-detail-card__content">
        <div className="asset-detail-card__info">
          <img src={`${imgStoragePath}aircraft_circle.png`} alt="img" className="asset-image" />
          <div className="asset-text">
            <span className="start-date">{assetDetail?.type}</span>
            <br />
            <span className="msn-wrapper">{`${assetDetail?.labelType}: ${assetDetail?.serialNo || ''}`}</span>
            <br />
            <div className="date-info">
              <div className="date-label">Start Date</div>
              <div className="date-label ">{startDate}</div>
            </div>
          </div>
        </div>
        <div className="asset-detail-card__status">
          <div> <div className={`status-indicator status${assetDetail?.clickedAsset?.status?.id ?? null}`}></div>
            <span className="status-label">{statusLabel}</span></div>
          <div className="date-info">
            <div className="date-label end-date">End Date</div>
            <div className="date-label ">{endDate}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssetPopover