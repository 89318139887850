import React from 'react'
import { Avatar } from '@material-ui/core'
import { imgStoragePath } from '../../../constants'
import { ToolTipHover } from '../../../shared'

const UserInfoCell = ({ user }) => {
  const { profile_pic, id, name, rating, designation, department, base_location, current_location, status, type, } = user;
  return (
    <>
      <Avatar alt="User Profile Picture" src={profile_pic ? profile_pic : imgStoragePath + "user.png"} style={{ height: "30px", width: "30px" }} />
      <div className="" style={{ cursor: "pointer" }}>
        <span>
          <h4
            id={`user-name-${id}`}
            style={{
              fontSize: "14px",
              fontFamily: "Conv_IBMPlexSans-SemiBold",
              padding: "0px 5px 0px 5px",
              display: "inline",
            }}
          >
            {name?.length > 21
              ? name.substring(0, 17) + "..."
              : name}
          </h4>
          {name?.length > 21 ? (
            <ToolTipHover
              placement="top"
              tagetId={`user-name-${id}`}
            >
              {name}
            </ToolTipHover>
          ) : null}
          <h6
            id={`user-designation-${id}`}
            style={{
              fontSize: "12px",
              margin: "0px 5px 1px 5px",
              color: "#aeacac",
              display: "inline",
            }}
          >
            {rating ? (
              <span>
                {parseFloat(rating.toFixed(2))}{" "}
                <i
                  class="fa fa-star"
                  style={{
                    fontSize: "10px",
                    color: "#cccccc",
                    position: "relative",
                    bottom: "1px",
                  }}
                ></i>{" "}
              </span>
            ) : (
              ""
            )}
          </h6>
        </span>
        <h6
          id={`user-designation-${id}`}
          style={{
            fontSize: "12px",
            margin: "0px 5px 1px 5px",
            color: "#aeacac",
          }}
        >
          {designation &&
            type.value != 3 &&
            designation.length > 25 ? (
            <span> {designation.substring(0, 22) + "..."} </span>
          ) : designation != null ? (
            <span> {designation} </span>
          ) : (
            ""
          )}
        </h6>
        {designation?.length > 25 ? (
          <ToolTipHover
            placement="top"
            tagetId={`user-designation-${id}`}
          >
            {designation}
          </ToolTipHover>
        ) : null}
        <h6
          id={`user-department-${id}`}
          style={{
            fontSize: "12px",
            margin: "0px 5px 1px 5px",
            color: "#aeacac",
          }}
        >
          {department &&
            department.label &&
            department.label.length > 25 ? (
            <span>
              {department.label.substring(0, 22) + "..."}{" "}
            </span>
          ) : department.label != null ? (
            <span> {department.label} </span>
          ) : (
            ""
          )}
        </h6>
        {department &&
          department.label &&
          department.label.length > 25 ? (
          <ToolTipHover
            placement="top"
            tagetId={`user-department-${id}`}
          >
            <span>{department.label}</span>
          </ToolTipHover>
        ) : null}
        <h6
          id={`user-cuurentlocation-${id}`}
          style={
            status.toLowerCase() == "deployed"
              ? {
                fontSize: "12px",
                margin: "0px 5px 1px 5px",
                color: "#aeacac",
              }
              : { display: "none" }
          }
        >
          {base_location && base_location.length > 18 ? (
            <span>
              {" "}
              {base_location.substring(0, 25) + "..."}{" "}
            </span>
          ) : base_location != null ? (
            <span> {base_location} </span>
          ) : (
            <span>
              {" "}
              {current_location
                ? current_location
                : ""}{" "}
            </span>
          )}
        </h6>
        {base_location && base_location.length > 18 ? (
          <ToolTipHover
            placement="top"
            tagetId={`user-cuurentlocation-${id}`}
          >
            <span>{user.base_location}</span>
          </ToolTipHover>
        ) : current_location &&
          current_location.length > 18 ? (
          <ToolTipHover
            placement="top"
            tagetId={`user-cuurentlocation-${id}`}
          >
            <span>{current_location}</span>
          </ToolTipHover>
        ) : null}
        <h6
          id={`user-baselocation-${id}`}
          style={
            status.toLowerCase() == "available"
              ? {
                fontSize: "12px",
                margin: "0px 5px 1px 5px",
                color: "#aeacac",
              }
              : { display: "none" }
          }
        >
          {base_location && base_location.length > 18 ? (
            <span>
              {" "}
              {base_location.substring(0, 15) + "..."}{" "}
            </span>
          ) : current_location != null ? (
            <span> {current_location} </span>
          ) : (
            ""
          )}
        </h6>
        {base_location && base_location.length > 18 ? (
          <ToolTipHover
            placement="top"
            tagetId={`user-baselocation-${id}`}
          >
            <span>{base_location}</span>
          </ToolTipHover>
        ) : null}
      </div></>
  )
}

export default UserInfoCell