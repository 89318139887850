import React from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Grid, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomFields from '../shared/CustomFields';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { CkEditorText } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
const EngineBoreScopeDetail = ({engine,params, onFieldChange}) => {
    const fields = [
        { name:"inspection_tsn",label:"TSN",type: "text", regex: regexConstants.numberWithDot,inputProps:{ maxLength: 10}},
        { name:"inspection_csn",label:"CSN",type: "text",regex: regexConstants.onlyNumeric,inputProps:{ maxLength: 10}},
        { name:"inspection_tslv",label:"TSLV",type: "text", regex: regexConstants.numberWithDot,inputProps:{ maxLength: 10}},
        { name:"inspection_cslv",label:"CSLV",type: "text",regex: regexConstants.onlyNumeric,inputProps:{ maxLength: 10}},
        { name:"inspection_tsr",label:"TSR",type: "text",regex: regexConstants.numberWithDot,inputProps:{ maxLength: 10}},
        { name:"inspection_csr",label:"CSR",type: "text",regex: regexConstants.onlyNumeric,inputProps:{ maxLength: 10}},
        { name:"inspection_tso",label:"TSO",type: "text",regex: regexConstants.numberWithDot,inputProps:{ maxLength: 10}},
        { name:"inspection_cso",label:"CSO",type: "text", regex: regexConstants.onlyNumeric,inputProps:{ maxLength: 10}},
        { name:"inspection_tshi",label:"TSHSI",type: "text",regex: regexConstants.numberWithDot,inputProps:{ maxLength: 10}},
        { name:"inspection_cshi",label:"CSHSI",type: "text",regex: regexConstants.onlyNumeric,inputProps:{ maxLength: 10}}]
    return(
        <div className="project-management-modal">
            <div style={{height :params?.workOrderSlug ? (window.innerHeight - 105) + 'px' :  (window.innerHeight - 312) + 'px', maxHeight: params?.workOrderSlug ? (window.innerHeight -266) + 'px' :  (window.innerHeight - 310) + 'px', overflow: 'auto', padding: '20px', position: '', marginLeft: '0px' }}>
                <Grid container spacing={1}>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                name="date_of_inspection"
                                label="Date Of Inspection"
                                format={fieldDateFormat}
                                fullWidth
                                clearable={true}
                                InputLabelProps={{ shrink: true }}
                                value={engine?.date_of_inspection || null}
                                onChange={(data, value) => { onFieldChange('date_of_inspection', data ? moment(data).format(backendDateFormat) : data); }}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            name="inspection_performed_by"
                            label="Inspection Performed By"
                            fullWidth
                            margin="normal"
                            value={engine?.inspection_performed_by ? engine?.inspection_performed_by : "" }
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => onFieldChange('inspection_performed_by', e.target.value)}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <CustomFields gridSize={3}  fields={fields} fieldData={engine}  onFieldChange={onFieldChange} />
                    <Grid item md={12}>
                        {engine && Object.keys(engine)?.length> 0 &&<CkEditorText 
                        htmlData={engine?.inspection_details || ""}
                        onChangeData={(data) => onFieldChange("inspection_details", data)}
                        /> }
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
export default EngineBoreScopeDetail;