import React, { useEffect, useState } from "react";
import moment from "moment";
import { TableRow, TableCell, Paper, Tooltip } from "@material-ui/core";
import { apiUrl, helperConst, manPowerTableHeader, manPowerFilter, manpowerStatusObj } from "..";
import UtilizationGraph from "./UtilizationChart";
import FilterExportPanel from "./FilterExportPanel";
import UtilizationIcon from "./UtilizationIcon"
import UserInfoModal from "./UserInfoModal";
import UserInfoCell from "./UserInfoCell";
import ManpowerPopover from "./ManpowerPopover";
import { TableWrapper, Popover } from "../shared";
import useGetConstants from "../hooks/useGetConstants";
import useManpowerListHook from "../hooks/useManpowerList";
import { displayDateFormatShort, imgStoragePath } from "../../../constants";
import { EmptyCollection } from "../../../shared";
import { PageLoader, Pagination } from "../../../shared_elements"; 

const ManPowerTracker = (props) => {
  const { skeletonLoader, setSkeletonLoader } = props
  let filterOptions
  const [userData, setUserData] = useState({});
  const [modalFlag, setModalFlag] = useState({ utilizationModal: false, userModal: false })
  const [selectedYear, setSelectedYear] = useState()
  const [anchorEl, setAnchorEl] = useState(null);
  const [projectDetail, setProjectDetail] = useState();
  const { filterData, getGlobalConstant } = useGetConstants(["project_departments", "user_category"])
  const { isLoading, filter, trackerData, fetchManpowerDetails } = useManpowerListHook(apiUrl.manpower_list_api, setSkeletonLoader)
  useEffect(() => { fetchManpowerDetails({ page: 1 }, "skeletonLoader", "",); getGlobalConstant() }, []);
  filterOptions = (Object.keys(filterData)?.length > 0) ?
    { ...manPowerFilter, department: { ...manPowerFilter.department, options: filterData?.departments }, category: { ...manPowerFilter.category, options: filterData?.category } } : manPowerFilter
  const handleFilter = (applyFilter) =>{ fetchManpowerDetails({ ...applyFilter, page: 1 },"pageLoader")}
  const handleExport = (file) => {fetchManpowerDetails({...filter, download: file.extension },"pageLoader", helperConst.EXPORT);}
  const handleUtilizationModal = () => { setModalFlag({ userModal: false, utilizationModal: true }); fetchManpowerDetails({ utilization: true },"pageLoader"); }
  const handleUserDetailModal = (userData) => { setUserData(userData); setModalFlag({ utilizationModal: false, userModal: true }); }
  const handleModalClose = () => setModalFlag({ utilizationModal: false, userModal: false });
  const onChangeDate = (value) => { setSelectedYear(value); fetchManpowerDetails({ utilization_year: value, utilization: true },"pageLoader") }
  const handleProjectPopover = (event, clickedCell) => { setAnchorEl(event.currentTarget); setProjectDetail(clickedCell) }
  const handlePopoverClose = () => setAnchorEl(null);
  const onChangePage=(event, newPage) => fetchManpowerDetails({...filter, page:newPage+1, per_page:trackerData?.pagination?.per_page}, 'pageLoader')
  const onChangeRowsPerPage=(event) => fetchManpowerDetails({...filter, page:1, per_page: event.target.value}, 'pageLoader')

  return (
    <>
      {isLoading ? <PageLoader /> : null}
      {!skeletonLoader && <><FilterExportPanel handleUtilizationModal={handleUtilizationModal} filterOptions={filterOptions}
        handleExport={handleExport} handleFilter={handleFilter} filter={filter} disabled={trackerData?.users?.length === 0 ? true : false}> <li>
          <UtilizationIcon disabled={trackerData?.users?.length === 0 ? true : false} onClick={handleUtilizationModal} />
        </li></FilterExportPanel>

        <div style={{ clear: "both", width: "100%" }}>
          <Paper>
            <div
              style={{ maxHeight: window.innerHeight - 230 + "px", "overflow-x": "auto" }}
            >
              <TableWrapper tableHeader={manPowerTableHeader}> {trackerData?.users?.map((user, index) => (
                <TableRow key={index + "manpowerTable"}>
                  <TableCell
                    className="d-flex align-items-center"
                    scope="row"
                    align="left"
                    onClick={() => handleUserDetailModal(user)}
                  >
                    <UserInfoCell user={user} />
                  </TableCell>
                  <TableCell scope="row" align="center">
                    <span>
                      {user?.projects?.length ? user?.projects?.length : 0} Project(s)</span>
                    {user?.projects?.length > 0 &&
                      <Tooltip title="Projects Info" arrow>
                        <span>  <img
                          className="user-info-icon"
                          onClick={(event) => handleProjectPopover(event, user?.projects)}
                          src={imgStoragePath + "inv_info_icon.png"}
                          alt=""
                        /></span>
                      </Tooltip>
                    }
                  </TableCell>
                  <TableCell scope="row" align="center">
                    {user?.status ? (
                      <span className={`project-status ${manpowerStatusObj[user.status]} `}
                      >{user?.status}</span>
                    ) : null}
                  </TableCell>
                     <TableCell style={{ width: "120px" }} >{user.start_date ? moment(user.start_date).format(displayDateFormatShort) : '--'}</TableCell>
                    <TableCell style={{ width: "120px" }}>{user.expected_end_date ? moment(user.expected_end_date).format(displayDateFormatShort) : '--'}</TableCell>
                </TableRow>
              ))}</TableWrapper>
            </div> 
            {!trackerData?.users?.length && !isLoading ? (
              <div style={{ textAlign: "center" }}>
                <EmptyCollection title={"No records found"} />
              </div>
            ) : null}
             <Pagination onChangePage={onChangePage} onChangeRowsPerPage={onChangeRowsPerPage} pagination={trackerData?.pagination} />
          </Paper>
          </div>
        {modalFlag?.utilizationModal ? <UtilizationGraph isLoading={isLoading} open={modalFlag?.utilizationModal} handleClose={handleModalClose} symbol="Hours Remaining :"
          yType="percentage"
          toolTipText="Percentage (%)"
          xLabel="Duration (Months)"
          yLabel="Manpower Usage (%)"
          legend="Hours"
          type="low"
          title="Life On Wings Analysis"
          color="#FEF7D1"
          lineColor="#F8D41C"
          onChangeDate={onChangeDate}
          events={[]}
          utilizationData={!isLoading && trackerData}
          selectedYear={selectedYear}
        /> : null}
        {modalFlag?.userModal ? <UserInfoModal isLoading={isLoading} open={modalFlag?.userModal} userData={userData} handleClose={handleModalClose} /> : null}</>}
      <Popover anchorEl={anchorEl} handleClose={handlePopoverClose}><ManpowerPopover popoverData={projectDetail} /></Popover>
    </>
  );
};

export default ManPowerTracker;