import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { helperFunc } from '../helperFunc';
import {Modal} from '../shared';
import { PageLoader } from '../../../shared_elements';
import {  displayDateFormatCardYear, } from '../../../constants';
require('highcharts/modules/exporting')(Highcharts);

export default function UtilizationGraph(props) {
  const { isLoading, open, selectedYear, utilizationData, color, lineColor, legend, xLabel, yLabel, handleClose, onChangeDate, } = props;
  const options = {
    chart: {
      type: 'area',
      zoomType: 'x'
    },
    title: {
      text: ''
    },
    xAxis: {
      title: {
        text: xLabel
      },
      categories: utilizationData && helperFunc.getChartData(utilizationData)?.categories
    },
    yAxis: {
      title: {
        text: yLabel
      },
      labels: {
        formatter: function () {
          return this.value + "%";
        }
      },
      gridLineDashStyle: 'longdash'
    },
    tooltip: {
      formatter: function () {
        return 'Month <b>' + this.x +
          '<br/></b>Percentage(%) <b>' + this.y + '</b>';
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      showInLegend: false,
      name: legend,
      data: utilizationData && helperFunc.getChartData(utilizationData)?.data,
      color: color,
      lineColor: lineColor,
      marker: {
        fillColor: lineColor
      }
    }],
    exporting: {
      buttons: {
        contextButton: {
          text: 'Export',
          menuItems: [
            {
              text: 'Export as PNG',
              onclick: function () {
                this.exportChart();
              }
            },
            {
              text: 'Export as SVG',
              onclick: function () {
                this.exportChart({ type: 'image/svg+xml' });
              }
            },
            {
              text: 'Export as PDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' });
              }
            }
          ]
        }
      }
    }
  };
  return <Modal handleClose={handleClose} open={open} modalTitle={"Utilization"} >
    {<div style={{ width: "600px" , height: "450px"}}>
      {isLoading ? <PageLoader /> :<><MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
        style={{width : "100px"}}
          margin="none"
          id="start_date"
          format={displayDateFormatCardYear}
          disableFuture
          openTo="year"
          views={["year"]}
          value={selectedYear}
          InputLabelProps={{ shrink: true }}
          onChange={(data) => onChangeDate(data ? moment(data).format("YYYY") : data)}
          onKeyDown={(e) => {e.preventDefault();}}
        />
      </MuiPickersUtilsProvider><HighchartsReact
        highcharts={Highcharts}
        options={options} /></> }
    </div>}
  </Modal>
}