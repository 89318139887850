import { checkApiStatus } from "../../utils_v2";
import { globalPutService,globalPostService,globalGetService } from "../../utils_v2/globalApiServices";

export const helperFunc = {
    fetchApi : (url, method = "get", data = {}, successCallback,errorCallback) => {
        const service = method === "post" ? globalPostService : method === "put"? globalPutService : globalGetService;
        service(url, data).then((response) => {
          if (checkApiStatus(response)) {
              successCallback({responseData:response?.data?.data, message :response?.data?.message} );
          }else{
            if(errorCallback){ errorCallback(response?.data?.message)}
            }
        });
      },
}