import React from "react";
import { Grid} from "@material-ui/core";
import CustomFields from "../shared/CustomFields";
const EngineInvoiceDetail = ({engine,params,onFieldChange}) => {
     const fields = [
            {
                type: "autocomplete",
                name: "qme",
                label: "QME",
                options: [{ name: 'Yes', id: "1" }, { name: 'No', id: "0" }, ],
              },
            {
                type: "autocomplete",
                name: "mr",
                label: "MR",
                options: [{ name: 'Yes', id: "1" }, { name: 'No', id: "0" }, ],
              },
            {
                type: "autocomplete",
                name: "lease_contract",
                label: "LC",
                options: [{ name: 'Yes', id: "1" }, { name: 'No', id: "0" }, ],
              },
            {
                type: "autocomplete",
                name: "end_of_lease",
                label: "EOL",
                options: [{ name: 'Yes', id: "1" }, { name: 'No', id: "0" }, ],
            },
            {
                type: "text",
                name: "ratio",
                label: "Hours/Cycles Ratio",
            },
            {
                type: "text",
                name: "mr_available_for_erp",
                label: "MR Available For EPR",
            },
            {
                type: "text",
                name: "mr_available_for_llp",
                label: "MR Available For LLP",
            },
            {
                type: "date",
                name: "as_on_date",
                label: "As On Date",
            },
          ];
    return(
        <div className="project-management-modal">
            <div style={{  height :params?.workOrderSlug ? (window.innerHeight - 100) + 'px' :  (window.innerHeight - 301) + 'px' , maxHeight: params?.workOrderSlug ? (window.innerHeight - 266) + 'px' :  (window.innerHeight - 312) + 'px', overflow: 'auto', padding: '20px', position: '', marginLeft: '0px' }}>
                <Grid container spacing={1}>
                <CustomFields gridSize={3}  fields={fields} fieldData={engine} onFieldChange={onFieldChange} />
                </Grid>
            </div>
        </div>
    )
}
export default EngineInvoiceDetail;