import React from 'react'
import { Grid, Paper } from '@material-ui/core';
import { onCheckPermission } from '../../../utils_v2';
import { ExportManu } from "../../../shared";
import FilterComponent from "../../../shared_elements/filter_component";

const FilterExportPanel = (props) => {
  const { filter, disabled, handleExport, handleFilter, children, filterOptions } = props
  return (
    <Paper square className="filter-cta">
      <Grid alignItems="center" container spacing={1}>
        <Grid item xs={9}>
          <FilterComponent
            filter={filter}
            filterMenu={filterOptions}
            getResponseBack={handleFilter}
          />
        </Grid>
        <Grid item xs={3}>
          <ul className="list-inline project-list-cta d-flex">
            <>
              {onCheckPermission("project_management", "tracker", "EXP") ? (
                <li className="list-inline-item">
                  <ExportManu variant = "outlined" size="small" disabled={disabled} title="Export" files={[{ title: "Excel", extension: "xls", key: "" },]} exportReportFn={handleExport} />
                </li>) : null}
              {children}
            </>
          </ul>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FilterExportPanel