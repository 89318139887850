import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions,Button,CircularProgress } from '@material-ui/core'
const DialogueWrapper = (props) => {
    const {className,dividers,open,toggleModalFn,dialogueTitle,isLoading,handleSave,dialogueWidth,buttonLabel,children}= props
    return (
        <Dialog
            maxWidth="md"
            open={open}
            onClose={toggleModalFn}
            className={className ? className :'project-management-modal'}
        >
            <DialogTitle id="scroll-dialog-title">{dialogueTitle}</DialogTitle>
            <DialogContent dividers={dividers ? dividers :true} style={{ width: dialogueWidth }}>{children}</DialogContent>
            <DialogActions>
                <Button disabled={isLoading} variant='outlined' color="primary" onClick={toggleModalFn} size="small">Cancel</Button>
                <Button disabled={isLoading}  onClick={handleSave} variant="contained" color="primary" size="small">{isLoading ? <CircularProgress size={20} /> : buttonLabel ? buttonLabel : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogueWrapper