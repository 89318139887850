import React from "react";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { onCheckPermission } from "../../../utils_v2";

const TrackerTab = ({ tabIndex, handleTabChange }) => {
  return (
    <div>
      <Paper className="project-tabs" style={{ marginBottom: "15px" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {onCheckPermission("project_management", "tracker", "R") && (
            <Tab label={`Project`} value="project" />
          )}
          <Tab label={`Manpower`} value="manpower" />
        </Tabs>
      </Paper>
    </div>
  );
};

export default TrackerTab;
