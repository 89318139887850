import React from 'react'
import { imgStoragePath } from '../../../constants';
import "./../assets/assetPopover.scss"

const ManpowerPopover = ({ popoverData }) => {
  return (
      <div className="manpower-popover-card">{popoverData?.map(ele => {
        return (
          <div className="manpower-popover-card__content">
            <div className="manpower-popover-card__info  col-12">
              <img src={`${imgStoragePath}project-name.png`} alt="img" className="asset-image" />
              <div className="asset-text">
                <span className="msn-wrapper">{ele?.name}</span>
              </div>
            </div>
            <div className='col-12 d-flex'>
              <div className="manpower-popover-card__status col-6">
                <div className="date-info ">
                  <div className="date-label">Start Date</div>
                  <div className="date-label font-color">{ele?.start_date}</div>
                </div>
                <div className="date-info">
                  <div className="date-label">Department</div>
                  <div className="date-label font-color">{ele?.department?.label}</div>
                </div></div>
              <div className="manpower-popover-card__status col-6">
                <div className="date-info ">
                  <div className="date-label ">End Date</div>
                  <div className="date-label font-color">{ele?.expected_end_date}</div>
                </div>
                <div className="date-info ">
                  <div className="date-label ">Location</div>
                  <div className="date-label font-color">{ele?.location}</div>
                </div>
              </div>
              </div>
          </div>
        )
      })}</div>
  )
}

export default ManpowerPopover