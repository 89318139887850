import React, { useState } from 'react'
import { TextField, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalPostService } from '../../../../../globalServices';
import { removeEmptyKey, toastFlashMessage, getLocalStorageInfo, checkApiStatus } from '../../../../../utils';
import { regexConstants } from '../../../../../constants/regEx';
import { PageLoader } from '../../../../../shared_elements';

export default function AddItemModal({ addItem, toggleModalFn, techConstants, workSlug, getResponseBack }) {
    const [modalData, setModalData] = useState({
        description: null,
        part_number: null,
        serial_number: null,
        cycles_remaining: null,
        acumen_remarks: null,
        status: { label: 'Open', value: 1 },
        comments: null,
        ref_document: null,
        operator_comments: null,
        engine_gap_report_section: addItem?.tabIndex,
        engine_work_order: null,
    })
    const [loading, setLoading]= useState(false)
    const onFieldChange = (e, key, value,) => {
        setModalData({ ...modalData, [key]: value })
    }
    const saveItemDetails = () => {
        let payload = Object.assign({}, modalData)
        payload = {
            ...payload,
            status: payload.status.value
        }
        setLoading(true)
        globalPostService(`technical/engine-workorder/${workSlug}/import-gap-report/`, [payload])
        .then(response => {
                setLoading(false)
                if(checkApiStatus(response)){
                    toggleModalFn()
                    // toastFlashMessage(response.data.message, 'success')
                    getResponseBack()
                }
            })
    }
    return (
        <div>
            {
                loading ? <PageLoader /> : null
            }
            <Dialog
                open={addItem.modal}
                onClose={toggleModalFn}
                maxWidth='md'
            >
                <DialogTitle id="scroll-dialog-title">
                    Add New Item
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={2}>
                        <Grid item sm='6' style={{ marginTop: '16px' }}>
                            <TextField
                                label='Description'
                                id={`name`}
                                fullWidth
                                margin="none"
                                value={modalData?.description}
                                onChange={(e) => onFieldChange(e, 'description', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                         <Grid item sm='6'>
                            <Autocomplete
                                disableClearable={true}
                                options={techConstants.filter(item => item.type == "engine_gap_report_status")}
                                getOptionLabel={option => option.label}
                                id="asset"
                                value={modalData.status ? modalData.status : null}
                                onChange={(e, value) => onFieldChange(e, 'status', value)}
                                renderInput={params => <TextField  {...params} label="Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        {
                            addItem?.tabIndex == 2 ?
                                <>
                                    <Grid item sm='6'>
                                        <TextField
                                            label='P/N'
                                            id={`part_number`}
                                            fullWidth
                                            margin="none"
                                            value={modalData?.part_number}
                                            onChange={(e) => onFieldChange(e, 'part_number', e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item sm='6'>
                                        <TextField
                                            label='S/N'
                                            id={`serial_number`}
                                            fullWidth
                                            margin="none"
                                            value={modalData?.serial_number}
                                            onChange={(e) => onFieldChange(e, 'serial_number', e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item sm='6'>
                                        <TextField
                                            label='Cycles Remaining'
                                            id={`cycles_remaining`}
                                            fullWidth
                                            margin="none"
                                            value={modalData?.cycles_remaining ? modalData?.cycles_remaining : ''}
                                            onChange={(e, value) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cycles_remaining', parseFloat(e.target.value) ): e.preventDefault()}
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                </>
                                : null

                        }
                        <Grid item sm='6'>
                            <TextField
                                label='Remarks'
                                id={`name`}
                                fullWidth
                                multiline
                                rows={addItem?.tabIndex == 2 ?1:2}
                                margin="none"
                                value={modalData?.acumen_remarks}
                                onChange={(e) => onFieldChange(e, 'acumen_remarks', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>

                        <Grid item sm='6'>
                            <TextField
                                label='Comments/Notes'
                                id={`comments`}
                                fullWidth
                                multiline
                                rows={2}
                                margin="none"
                                value={modalData?.comments}
                                onChange={(e) => onFieldChange(e, 'comments', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm='6'>
                            <TextField
                                label='Ref Documents'
                                id={`name`}
                                fullWidth
                                multiline
                                rows={2}
                                margin="none"
                                value={modalData?.ref_document}
                                onChange={(e) => onFieldChange(e, 'ref_document', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm='6'>
                            <TextField
                                label='Operator Comments'
                                id={`name`}
                                fullWidth
                                multiline
                                rows={2}
                                margin="none"
                                value={modalData?.operator_comments}
                                onChange={(e) => onFieldChange(e, 'operator_comments', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} color="primary" size='small' variant='outlined'>Cancel</Button>
                    <Button onClick={saveItemDetails} color="primary" size='small' variant='contained'>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
