import React, { useState } from 'react'
import { Link } from 'react-router';
import { Paper, Button, Table, TableHead, TableRow, TableCell, TableBody, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';
import { engineLinksHeader } from '..';
import DialogueWrapper from '../shared/Dialogue';
import { getLocalStorageInfo } from '../../../utils_v2';
import { imgStoragePath, displayDateFormat } from '../../../constants';
import { EmptyCollection } from '../../../shared';
const userInfo = getLocalStorageInfo();
const AddEditReferenceLinks = ({ engine, setEngine, referenceLinks, toggleModalFn }) => {
    const { id, name, profile_pic } = userInfo.user
    const [fieldData, setFieldData] = useState({})
    const handleAddRow = () => {
        const newLink = { link: fieldData?.link, description: fieldData?.description, updated_at: moment(), user: { id: id, name: name, profile_pic: profile_pic } };
        if (engine?.reference_links?.length > 0) setEngine({ ...engine, reference_links: [...engine.reference_links, newLink] })
        else setEngine({ ...engine, reference_links: [newLink] });
        toggleModalFn()
        setFieldData({})
    };
    const onFieldChange = (keyParam, value) => setFieldData({ ...fieldData, [keyParam]: value })
    return (
        <DialogueWrapper open={referenceLinks.addModal} toggleModalFn={toggleModalFn} dialogueTitle="Reference " handleSave={handleAddRow} dialogueWidth="400px" buttonLabel="Add">
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <div>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label='Link'
                            value={fieldData?.link}
                            onChange={(e) => onFieldChange('link', e.target.value)}
                            fullWidth
                            variant='outlined'
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label='Description'
                            value={fieldData?.description}
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            fullWidth
                            variant='outlined'
                            style={{ marginTop: "10px" }}
                        />
                    </div>
                </Grid>
            </Grid></DialogueWrapper>
    )
}
const EngineReferenceLinks = ({params, engine, setEngine , handleMultiDelete,onMultiFieldChange }) => {
    const [referenceLinks, setReferenceLinks] = useState({ addModal: false });
    return (
        <div >
            <Grid container style={{ justifyContent: 'flex-end', padding: '4px 8px' }}>
                <Button size='small' color='primary' variant='outlined' onClick={() => setReferenceLinks({ addModal: true })}>
                    Add Reference Links
                </Button>
            </Grid>
            <Paper square style={{ height:params?.workOrderSlug  ?  (window.innerHeight - 305) + 'px' : (window.innerHeight - 351) + "px", overflow: 'auto' }}>
                <Table stickyHeader className='mui-table-format' style={{ height: 'auto', overflow: 'auto' }}>
                    <TableHead><TableRow>{engineLinksHeader?.map((headerField, index) => <TableCell key={headerField?.label + index} >{headerField?.label}</TableCell>)}</TableRow></TableHead>
                    <TableBody>
                        {engine?.reference_links?.length > 0 ? engine?.reference_links?.map((link, index) => (
                           <TableRow key={index}>
                            { console.log("link",link)}
                                <TableCell>
                                    <TextField
                                        value={link.link}
                                        onChange={(e) => onMultiFieldChange(index, 'link', e.target.value,"reference_links")}
                                        fullWidth
                                        variant='outlined'
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={link.description}
                                        onChange={(e) => onMultiFieldChange(index, 'description', e.target.value,"reference_links")}
                                        fullWidth
                                        variant='outlined'
                                    />
                                </TableCell>
                                <TableCell><div className='d-flex align-items-center'> {link?.user?.profile_pic ? <img style={{ borderRadius: "50%" }} height={30} width={30} src={link?.user?.profile_pic} alt="user image" /> : <img src={imgStoragePath + 'user.png'} alt="user image" />} <div style={{ marginLeft: "5px" }}>  {link?.user?.name}<p style={{ fontSize: '11px', opacity: '0.8' }}>{moment().format(displayDateFormat)}</p></div> </div></TableCell>
                                <TableCell>
                                    <div className='flex-centered'>
                                       <Tooltip title="Link" arrow><Link target="_blank" to={link.link} ><i className="fas fa-external-link-alt" style={{ color: '#aaa', fontSize: '14px', position: 'relative', top: '0.5px' }}></i></Link></Tooltip> 
                                       <Tooltip title="Remove" arrow><IconButton onClick={() =>handleMultiDelete(index,"reference_links")}><CancelIcon color='secondary' fontSize="small" /> </IconButton></Tooltip> 
                                    </div>
                                </TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
                {!engine?.reference_links?.length ?  <EmptyCollection title="No reference links found" /> : null}
                <AddEditReferenceLinks engine={engine} setEngine={setEngine} referenceLinks={referenceLinks} toggleModalFn={() => setReferenceLinks({  addModal: false})} />
            </Paper>
        </div>
    )
}

export default EngineReferenceLinks



