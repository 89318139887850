import React, { Fragment } from 'react';
import { withRouter, browserHistory } from 'react-router'
import WorkOrderAsset from './WorkOrderAsset';
import { Chip, Grid, Tooltip } from '@material-ui/core';
import { dateTransformAllSet, onCheckPermission } from '../../../utils_v2';
import { projectsSelfPermission } from '../'
const WorkOrderHd = ({params, project, workOrder, onEditWorkOrder,onDeleteWorkOrder, permission, onAssignEngineer, setDeleteEngModal, asset}) => {
  return(
    <Grid container spacing={1} className="work-order-table">
      <Grid item xs={12} md={3}>
        {(() => {
          if (workOrder.asset.type === 1){
            return(
              <WorkOrderAsset
                permission={permission}
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_Aircraft.png"
                serialNo={`MSN ${workOrder.asset.msn}`}
                type={workOrder.asset.aircraft_type}
                progress={workOrder.progress}
                asset={workOrder.asset}
                workOrder={workOrder}
                onEditWorkOrder={onEditWorkOrder}
                project={project}
                work_order_number={workOrder.work_order_number}
                onDeleteWorkOrder={onDeleteWorkOrder}
              />
            )
          }else if (workOrder.asset.type === 2) {
            return(
              <WorkOrderAsset
                permission={permission}
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_engine.png"
                serialNo={`ESN ${workOrder.asset.esn}`}
                type={workOrder.asset.engine_type}
                asset={workOrder.asset}
                progress={workOrder.progress}
                workOrder={workOrder}
                // onEditWorkOrder={() => browserHistory.push(`/technical/project/${params.project_slug}/engine/edit/${workOrder.slug}`)}
                onEditWorkOrder={() => browserHistory.push(`/technical/project/${params.project_slug}/engine/edit/${workOrder.slug}/v2`)}
                work_order_number={workOrder.work_order_number}
                project={project}
                onDeleteWorkOrder={onDeleteWorkOrder}
              />
            )
          }else if (workOrder.asset.type === 3) {
            return(
              <WorkOrderAsset
                permission={permission}
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_apu.svg"
                serialNo={`APU ${workOrder.asset.serial_number}`}
                type=''
                progress={workOrder.progress}
                asset={workOrder.asset}
                workOrder={workOrder}
                onEditWorkOrder={onEditWorkOrder}
                project={project}
                work_order_number={workOrder.work_order_number}
                onDeleteWorkOrder={onDeleteWorkOrder}
              />
            )
          }else if (workOrder.asset.type === 4) {
            return(
              <WorkOrderAsset
                permission={permission}
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_lg.svg"
                serialNo={`LG ${workOrder.asset.serial_number}`}
                type=''
                asset={workOrder.asset}
                progress={workOrder.progress}
                workOrder={workOrder}
                onEditWorkOrder={onEditWorkOrder}
                project={project}
                work_order_number={workOrder.work_order_number}
                onDeleteWorkOrder={onDeleteWorkOrder}
              />
            )
          }else if (workOrder.asset.type === 5) {
            return(
              <WorkOrderAsset
                permission={permission}
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_propeller.svg"
                serialNo={`PROPELLER ${workOrder.asset.serial_number}`}
                type=''
                progress={workOrder.progress}
                asset={workOrder.asset}
                workOrder={workOrder}
                onEditWorkOrder={onEditWorkOrder}
                project={project}
                work_order_number={workOrder.work_order_number}
                onDeleteWorkOrder={onDeleteWorkOrder}
              />
            )
          }
        })()}
      </Grid>
      <Grid item xs={3} md={2}>
        <p>Forms</p>
        <h4>{workOrder.forms.length}</h4>
      </Grid>
      <Grid item xs={6} md={3}>
        <p>Last Activity</p>
        <h4>{dateTransformAllSet(workOrder.updated_at)}</h4>
      </Grid>
      <Grid item xs={3} md={2}>
        <p>Status</p>
        <h4>{workOrder.status.label}</h4>
      </Grid>
      <Grid item xs={3} md={2}>
        <p>Leads</p>
        {workOrder?.leads?.length ?
          <h4>{workOrder.leads.map((item, index) =>
            projectsSelfPermission ('workorder','U', workOrder, permission?.update?.value||null)  && (project?.status?.id && ![0,7,8].includes(project.status.id)) ?
              <span><Chip size='small' label={<Tooltip title={item?.name} arrow placement='top'>
                <span style={{ fontSize: '11px' }}>{item?.name}</span>
              </Tooltip>
              } style={{ background: '#e7e7e7', margin: '0px 4px 4px 0px', maxWidth: '206px' }} onDelete={(e) => setDeleteEngModal({ data: item, modal: true, assetType: asset?.type, workOrderSlug: workOrder?.slug })} /></span>
              :
              <span><Chip size='small' label={<Tooltip title={item?.name} arrow placement='top'>
                <span style={{ fontSize: '11px' }}>{item?.name}</span>
              </Tooltip>
              } style={{ background: '#e7e7e7', margin: '0px 4px 4px 0px', maxWidth: '206px' }} /></span>
          )}</h4> : null
        }
        { onCheckPermission('project_management', 'workorder','U') && projectsSelfPermission('workorder','U', workOrder, permission?.update?.value||null)  && (project?.status?.id && ![0,7,8].includes(project.status.id)) ?
          <span onClick={(e)=> {e.preventDefault();e.stopPropagation(); onAssignEngineer()}} style={{color:'#3f51b5', fontSize:'13px', textDecoration:'underline'}}>Assign</span>:null
        }
      </Grid>
    </Grid>
  )
}
export default withRouter(WorkOrderHd)
