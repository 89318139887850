import React from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Modal = ({ open, maxWidth, children, handleClose, className, modalTitle }) => {
  return (
    <Dialog maxWidth={maxWidth ? maxWidth : "lg"} open={open} onClose={handleClose} disableBackdropClick className={className ? className : 'project-management-modal'}><DialogTitle ><div className={` ${modalTitle ? 'content-space-between' : "justify-content-end"} modal-header-divider `}>{modalTitle ? modalTitle : ""} <IconButton aria-label="close" onClick={handleClose} style={{ left: "16px", }} > <Tooltip title="Close"><span> <CloseIcon /></span></Tooltip> </IconButton></div>  </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

export default Modal