import React from 'react';
import { ToolTipHover, InfoIcon } from '../../../../shared';
import ManPowerPopOver from './ManPowerPopOver';
import {imgStoragePath} from '../../../../constants';

const ManTrackerSideInfo = (props) => {
  return (
    <div className="simulation-month-sidebar item-block">
      <div className="row-header">
          { props.userList.map((user, index) =>
            <div key={index} className="item-detail-block flex-not-centered" style={{height: user.projects && user.projects.length && user.projects.length > 2 ? (25 * user.projects.length+30)+'px' : '90px'}}>
              <div onClick={() => props.getUserInfo(user)} className="innerheading-block flex-centered" style={{ width: '243px', flexBasis: '243px', padding:'15px'}}>
                <img style={{width: '30px', height: '30px', borderRadius: '50%'}} src={user.profile_pic?user.profile_pic:imgStoragePath+'user.png'} alt="" />
                <div className="" style={{cursor: 'pointer'}}>
                <span>
                  <h4 id={`user-name-${user.id}`} style={{fontSize: '14px', fontFamily: 'Conv_IBMPlexSans-SemiBold', padding: '0px 5px 0px 5px',display:'inline'}}>
                  {user.name&&user.name.length > 21 ? user.name.substring(0,17)+'...':user.name}
                  </h4>
                  { user.name&&user.name.length > 21 ?
												<ToolTipHover placement="top" tagetId={`user-name-${user.id}`}>
													{user.name}
												</ToolTipHover>
												: null
											}
                  <h6 id={`user-designation-${user.id}`} style={{fontSize: '12px', margin: '0px 5px 1px 5px',color: '#aeacac',display:'inline'}}>
                   { user.rating ?<span>{parseFloat(user.rating.toFixed(2))} <i class="fa fa-star" style={{ fontSize: '10px', color: '#cccccc', position: 'relative', bottom: '1px' }}></i> </span> : ''}
                   {
                     // user.type.value == 3 ?  <span style={{ fontFamily : "Conv_IBMPlexSans-Regular", border: '1px solid #c4cde3', background: "#e6e9f2", borderRadius: "16px", fontSize: '12px', padding: "2px 5px", marginLeft: "3px", display: "inline", position: "relative", bottom: "1px" }} >Contractor</span> : null
                   }
                  </h6>
                  </span>
                  <h6 id={`user-designation-${user.id}`} style={{fontSize: '12px', margin: '0px 5px 1px 5px',color: '#aeacac'}}>
                   {user.designation && user.type.value != 3 && user.designation.length > 25 ? <span> {user.designation.substring(0,22)+'...'} </span>:user.designation != null ? <span>  {user.designation} </span> : ''}
                  </h6>
                  { user.designation&&user.designation.length > 25 ?
												<ToolTipHover placement="top" tagetId={`user-designation-${user.id}`}>
													{user.designation}
												</ToolTipHover>
												: null
                  }
                  <h6 id={`user-department-${user.id}`} style={{fontSize: '12px', margin: '0px 5px 1px 5px',color: '#aeacac'}}>
                   {user.department && user.department.label&&user.department.label.length > 25 ? <span> {user.department.label.substring(0,22)+'...'} </span>:user.department.label != null ? <span>  {user.department.label} </span> : ''}
                   </h6>
                   { user.department && user.department.label&&user.department.label.length > 25 ?
											<ToolTipHover placement="top" tagetId={`user-department-${user.id}`}>
                        <span>
  												{user.department.label}
                        </span>
											</ToolTipHover>
											: null
                   }
                    {/* <h6 id={`user-category-${user.id}`} style={{fontSize: '12px', margin: '0px 5px 1px 5px',color: '#aeacac'}}>
                   {user.category && user.category.label&&user.category.label.length > 25 ? <span> {user.category.label.substring(0,22)+'...'} </span>:user.category &&user.category.label  ? <span>  {user.category.label} </span> : ''}
                   </h6>
                   { user.category && user.category.label&&user.category.label.length > 25 ?
											<ToolTipHover placement="top" tagetId={`user-category-${user.id}`}>
                        <span>
  												{user.category.label}
                        </span>
											</ToolTipHover>
											: null
                   } */}
                  <h6 id={`user-cuurentlocation-${user.id}`} style={user.status.toLowerCase() == 'deployed' ? {fontSize: '12px', margin: '0px 5px 1px 5px',color: '#aeacac'} : {display: 'none'}}>
                   {user.base_location&&user.base_location.length > 18 ? <span> {user.base_location.substring(0,25)+'...'} </span>:user.base_location != null ? <span>  {user.base_location} </span> : <span>  {user.current_location ? user.current_location: ''} </span>}
                   </h6>
                   {user.base_location && user.base_location.length > 18 ?
											<ToolTipHover placement="top" tagetId={`user-cuurentlocation-${user.id}`}>
                        <span>
  												{user.base_location}
                        </span>
											</ToolTipHover>
											: user.current_location && user.current_location.length > 18 ?
   											<ToolTipHover placement="top" tagetId={`user-cuurentlocation-${user.id}`}>
                           <span>
     												{user.current_location}
                           </span>
   											</ToolTipHover>
   											: null
                   }
                  <h6 id={`user-baselocation-${user.id}`} style={user.status.toLowerCase() == 'available' ? {fontSize: '12px', margin: '0px 5px 1px 5px',color: '#aeacac'} : {display: 'none'}}>
                   {user.base_location&&user.base_location.length > 18 ? <span> {user.base_location.substring(0,15)+'...'} </span>:user.current_location != null ? <span>  {user.current_location} </span> : ''}
                   </h6>
                   {user.base_location && user.base_location.length > 18 ?
											<ToolTipHover placement="top" tagetId={`user-baselocation-${user.id}`}>
                        <span>
  												{user.base_location}
                        </span>
											</ToolTipHover>
											: null
                   }
                </div>
              </div>
              <div className="innerheading-block flex-centered" style={{fontSize: '14px', width: '131px', flexBasis: '131px', padding:'15px'}}>
                {user.projects.length} Project(s)
                <ManPowerPopOver
													placement= 'right'
													tagetId={'Popoveruser-'+user.id+'-1'}
													assets = {user.projects}
												/>
              </div>
              <div className="innerheading-block flex-centered" style={{fontSize: '14px',color:user.status && user.status != 'Available' && user.status != 'Leave' ? '#9a5455':'#319c46', width: '111px', flexBasis: '111px', padding:'15px'}}>
                <span>
                  {user.status == 'Available' ? user.status : user.status == 'Deployed' ? user.status : user.status == 'Leave' ? 'Available' : 'Not Available'}
                  {
                    user.remarks ?
                      <img style={{width: '15px', height: '15px', borderRadius: '50%', marginLeft: '5px'}} src={imgStoragePath+'inv_info_icon.png'} alt="" id={`user-remarks-${user.id}`}/>
                    :null
                  }
                  {
                    user.remarks ?
                    <ToolTipHover placement="right" tagetId={`user-remarks-${user.id}`}>
                      <span>
                        {user.remarks}
                      </span>
                    </ToolTipHover>
                    :null
                  }
                </span>
              </div>
            </div>
          )}
      </div>
    </div>
  )
}
export default ManTrackerSideInfo;
