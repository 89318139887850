import React from "react";
import { Button } from "@material-ui/core";
import showUlilIcon from "../../../shared/assets/img/show_util.svg";

const UtilizationIcon = (props) => {
  const {onClick ,disabled} = props;
  return (
    <div>
      <Button
        aria-haspopup="true"
        onClick={onClick}
        color="primary"
        disabled={disabled}
        variant="outlined"
        size="small"
      >
        <>
          <img
            width="13"
            style={{ marginRight: "3px" }}
            src={showUlilIcon}
            alt=""
          />
          Show Utilization
        </>
      </Button>
    </div>
  );
};

export default UtilizationIcon;
