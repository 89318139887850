import React from 'react';
import { Link } from 'react-router';
import { Paper, Button, Tabs, Tab, CircularProgress } from '@material-ui/core';
import { EngineWorkOrderDetail, EngineInvoiceDetail, EngineBoreScopeDetail, EngineAttachments, EngineReferenceLinks } from '../components'
import EngineShopVisit from '../components/EngineShopVisit';

// const AddEditShopVisit = ({ addEditShopVisit, toggleModalFn }) => {
//     const [isLoading, setLoading] = useState(false);

//     const onAddEditShopVisit = () => {

//     }
//     return (
//         <>
//             <Dialog
//                 maxWidth="md"
//                 open={addEditShopVisit.modal}
//                 onClose={toggleModalFn}
//                 className='project-management-modal'
//             >
//                 <DialogTitle id="scroll-dialog-title">
//                     ShopVisit
//                 </DialogTitle>
//                 <DialogContent dividers={true}></DialogContent>
//                 <DialogActions>
//                     <Button disabled={isLoading} color="primary" onClick={toggleModalFn} size="small">Cancel</Button>
//                     <Button disabled={isLoading} onClick={onAddEditShopVisit} variant="contained" color="primary" size="small">
//                         {isLoading ? <CircularProgress size={20} /> : 'Save'}
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </>
//     )
// }

const EngineWorkOrder = ({ engine,onMultiFieldChange,handleMultiDelete,onAddEngine, onFieldChange, error, enginesList, engineTypes, aircraftTypes, pltConstants, params,isLoading,isButtonLoading,setEngine,tabIndex,setTabIndex }) => {
    return (
        <>
            <div style={{ position: 'relative' }}>
                <div style={{ overflow: '', marginLeft: '' }}>
                    <Paper >
                        <Tabs
                            value={tabIndex}
                            onChange={(event, newValue) => setTabIndex(newValue)}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label={<span>Engine Current Details</span>} value="engine-current-details" />
                            {/* <Tab label={<span>Shop Visits</span>} value="shop-visits"/> */}
                            <Tab label={<span>MR and Invoice Details</span>} value="mr-and-invoice-details" />
                            <Tab label={<span>Borescope Inspection Details</span>} value="borescope-inspection-details" />
                            <Tab label={<span>Attachments</span>} value="attachments" />
                            <Tab label={<span>Reference Links</span>} value="reference-links" />
                        </Tabs>
                    </Paper>
                    {tabIndex === 'engine-current-details' ?
                        <EngineWorkOrderDetail engine={engine} params={params} error={error} enginesList={enginesList} engineTypes={engineTypes} aircraftTypes={aircraftTypes} pltConstants={pltConstants} onFieldChange={onFieldChange}  isLoading={isLoading} /> : null
                    }
                    {/* {tabIndex === 'shop-visits' ?
                        <EngineShopVisit  /> : null
                    } */}
                    {tabIndex === 'mr-and-invoice-details' ?
                        <EngineInvoiceDetail  params={params} engine={engine} onFieldChange={onFieldChange}/> : null
                    }
                    {tabIndex === 'borescope-inspection-details' ?
                        <EngineBoreScopeDetail engine={engine} params={params} onFieldChange={onFieldChange} /> : null
                    }

                    {tabIndex === 'attachments' ?
                        <EngineAttachments engine={engine} setEngine={setEngine} params={params}  handleMultiDelete={handleMultiDelete} onMultiFieldChange={onMultiFieldChange}/>: null
                    }
                    {tabIndex === 'reference-links' ?
                       <EngineReferenceLinks engine={engine} params={params} setEngine={setEngine}  handleMultiDelete={handleMultiDelete} onMultiFieldChange={onMultiFieldChange} /> : null
                    }
                </div>
                <div className="divider" style={{ height: '51px' }}></div>
                <Paper square style={{ padding: '10px', borderTop: '1px solid #d7d7d7', position: 'absolute', bottom: '0', left: '0', width: '100%', background: '#fff', zIndex: 9 }}>
                    <ul className="list-inline" style={{ float: 'right' }}>
                        <li className="list-inline-item">
                            <Link to={"/technical/project/view/" + params.project_slug} ><Button disabled={isLoading} variant='outlined' color="primary" size="small">Cancel</Button></Link>
                        </li>
                        <li className="list-inline-item">
                            <Button disabled={isButtonLoading} onClick={onAddEngine} variant="contained" color="primary" size="small">{isButtonLoading ? <CircularProgress size={20} /> : "Save"}</Button>
                        </li>
                    </ul>
                </Paper>
            </div>
        </>
    )
}
export default EngineWorkOrder;