import React from 'react';
import Popover from '@material-ui/core/Popover';

const PopoverComponent = (props) => {
  const { anchorEl,  handleClose, children } = props
  const open = Boolean(anchorEl);

  return (
    <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{vertical: 'left',horizontal: 'right',}} transformOrigin={{vertical: 'left',horizontal: 'left',}}>
      {children}
    </Popover>
  );
}

export default PopoverComponent