import React, { useState } from 'react'
import { globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../../utils_v2';

const useGetConstants = (constantType) => {
    const [filterData, setFilterData] = useState({});
    const getGlobalConstant = () => {
        globalPostService('console/get-constants/', { constant_types: constantType })
            .then(response => {
                if (checkApiStatus(response)) {
                    let filteredData = { departments: [], projectType: [], category: [] }
                    const keyMapping = {
                        project_departments: 'departments',
                        project_name: 'projectType',
                        user_category: 'category'
                    };
                    
                    response.data.data.forEach(departmentData => {
                        const key = keyMapping[departmentData.type];
                        if (key) {
                            filteredData[key].push({ ...departmentData, id: departmentData.value, name: departmentData.label });
                        }
                    });
                    setFilterData(filteredData)
                }
            })
    }
    return { filterData, getGlobalConstant }
}

export default useGetConstants