import React, { useState } from 'react'
import { Link } from 'react-router';
import moment from 'moment';
import { Paper, Button, TextField, Table, TableHead, TableRow, TableCell, TableBody,  Grid, IconButton, Tooltip } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import GetAppIcon from '@material-ui/icons/GetApp';
import CancelIcon from '@material-ui/icons/Cancel';
import { engineAttachmentsHeader } from '..';
import DialogueWrapper from '../shared/Dialogue';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils_v2';
import { globalPostService } from '../../../utils_v2/globalApiServices';
import { displayDateFormat, imgStoragePath } from '../../../constants';
import { EmptyCollection } from '../../../shared';
const userInfo = getLocalStorageInfo();
const AddEditAttachment = ({ engine, setEngine, addEditAttachment, toggleModalFn, attachment, setAttachment }) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState()
    const onAddEditAttachment = () => {
        setLoading(true)
        const { id, name, profile_pic } = userInfo.user
        const payload = new FormData();
        if (attachment?.files && attachment?.files?.length > 0) { payload.append('file', attachment?.files[0]) }
        globalPostService(`technical/engine-workorder/file-upload/`, payload)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAttachment({})
                    const updatedObj = { attachment: response?.data?.url, name: response?.data?.name, created_at: moment(), description: attachment?.description, user: { id: id, name: name, profile_pic: profile_pic }, }
                    if (engine.attachments?.length > 0) { setEngine({ ...engine, attachments: [...engine.attachments, updatedObj] }) }
                    else { setEngine({ ...engine, attachments: [updatedObj] }) }
                    toggleModalFn();
                }
                setLoading(false);
            })
    }
    const onFieldChange = (keyParam, value) => setAttachment({ ...attachment, [keyParam]: value })
    return (
        <DialogueWrapper open={addEditAttachment.modal} toggleModalFn={toggleModalFn} isLoading={isLoading} dialogueTitle="Attachment" handleSave={onAddEditAttachment} dialogueWidth="400px" buttonLabel="Add">  
        <Grid container spacing={1}>
            <Grid item md={12}>
                <div>
                    <DropzoneArea
                        acceptedFiles={['text/csv', "application/xls", ",application/excel", , ",application/vnd.ms-excel", 'application/pdf']}
                        filesLimit={1}
                        maxFileSize={104857600}
                        showPreviewsInDropzone={false}
                        useChipsForPreview={true}
                        showPreviews={true}
                        dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> (supported files format : csv,xls,excel,pdf) </p>}
                        dropzoneClass="drag-drop-cnt"
                        showAlerts={['info', 'error']}
                        alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                        clearOnUnmount={true}
                        onDrop={(files) => { setAttachment({ ...attachment, 'files': files }); setError({ ...error, 'files': '' }) }}
                    />
                </div>
                {attachment?.files && attachment?.files?.length > 0 ?
                    <div style={{ marginTop: "12px" }}><TextField
                        InputLabelProps={{ shrink: true }}
                        label='Description'
                        value={attachment.description}
                        onChange={(e) => onFieldChange('description', e.target.value)}
                        fullWidth
                        variant='outlined'
                    /></div> : null}
            </Grid>
        </Grid></DialogueWrapper>
    )
}
const EngineAttachments = ({ params,engine,setEngine, handleMultiDelete,onMultiFieldChange }) => {
    const [addEditAttachment, setAddEditAttachment] = useState({ modal: false, data: null });
    const [attachment, setAttachment] = useState(addEditAttachment.data);
    return (
        <div className='project-management-modal'>
            <Grid container style={{ justifyContent: 'right', padding: '4px 8px' }}>
                    <Button size='small' color='primary' variant='outlined' onClick={() => setAddEditAttachment({ modal: true, data: null })} >ADD Attachment</Button>
                </Grid>
            <Paper square style={{ height:params?.workOrderSlug ? (window.innerHeight - 305) + 'px' : (window.innerHeight - 351) + 'px' , overflow: 'auto', position: '', marginLeft: '' }} >
                <Table stickyHeader style={{ height: 'auto', overflow: 'auto' }} className='mui-table-format' >
                    <TableHead><TableRow>{engineAttachmentsHeader?.map((headerField, index) => <TableCell key={headerField?.label + index} style={{ width: headerField?.width }}>{headerField?.label}</TableCell>)}</TableRow></TableHead>
                    <TableBody>
                        {engine?.attachments?.length > 0 ? engine?.attachments?.map((bodyField, index) => {
                            return (<TableRow key={index + bodyField.name}> <TableCell>{bodyField?.name}</TableCell>
                                <TableCell><TextField
                                    value={bodyField.description}
                                    onChange={(e) => onMultiFieldChange(index, 'description', e.target.value,"attachments")}
                                    fullWidth
                                    variant='outlined'
                                /></TableCell>
                                <TableCell><div className='d-flex align-items-center'> {userInfo?.user?.profile_pic ? <img style={{ borderRadius: "50%" }} height={30} width={30} src={userInfo?.user?.profile_pic} alt="user image" /> : <img src={imgStoragePath + 'user.png'} alt="user image" />} <div style={{ marginLeft: "5px" }}>  {userInfo?.user?.name}<p style={{ fontSize: '11px', opacity: '0.8' }}>{moment().format(displayDateFormat)}</p></div> </div></TableCell>
                                <TableCell><div className='flex-centered' > 
                                    <Tooltip title='Download' arrow><Link target="_blank" to={bodyField?.attachment}><GetAppIcon color='primary' variant="outlined" fontSize="small" /> </Link></Tooltip> 
                                    <Tooltip title='Remove' arrow><IconButton onClick={() => handleMultiDelete(index,"attachments")}><CancelIcon color='secondary' fontSize="small" /> </IconButton></Tooltip> </div></TableCell>
                                </TableRow>)
                        }) : null}
                    </TableBody>
                </Table>
                 {!engine?.attachments.length ? <EmptyCollection title="No attachments found" />:null }
                <AddEditAttachment engine={engine} setEngine={setEngine}  addEditAttachment={addEditAttachment} attachment={attachment} setAttachment={setAttachment} toggleModalFn={() => setAddEditAttachment({ modal: false, data: null })} />
            </Paper>
           
        </div>
    )
}

export default EngineAttachments