import React from "react";
import { Table,TableBody,TableHead,TableRow,TableCell,} from "@material-ui/core";

const TableWrapper = (props) => {
  const { tableHeader, children } = props;
  return (
    <div>
      <Table className="mui-table-format" stickyHeader size="medium">
        <TableHead><TableRow>{tableHeader.map((thead) => {return (<TableCell align={thead.alignPosition}>{thead.label}</TableCell>);})}</TableRow></TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </div>
  );
};

export default TableWrapper;
