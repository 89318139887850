import React, { useState } from 'react'
import { helperConst } from '..';
import { globalGetService } from '../../../globalServices';
import { checkApiStatus } from '../../../utils_v2';
import { useToastMessage } from '../../../shared_elements';

const useManpowerListHook = (apiUrl, setSkeletonLoader) => {
  const toastMessage = useToastMessage()
  const [isLoading, setLoading] = useState(false)
  const [trackerData, setTrackerData] = useState({users : [],pagination : {}})
  const [filter, setFilter] = useState(false)
  const fetchManpowerDetails = (query, loader, type,) => {
    if (loader === "skeletonLoader") {
      setSkeletonLoader(true)
    } else if (loader === "pageLoader") {
      setLoading(true)
    }
    globalGetService(apiUrl, query).then((response) => {
      const respMessage = response?.data?.message
      if (checkApiStatus(response)) {
      if (type === helperConst.EXPORT) {
          toastMessage(respMessage, "success")
      }else{
          setTrackerData({...trackerData,... response?.data?.data})
          delete query?.page;
          delete query?.per_page;
          delete query?.utilization;
          delete query?.utilization_year;
          setFilter(query)
        }
      } 
      if (loader === "skeletonLoader") {
        setSkeletonLoader(false)
      } else if (loader === "pageLoader") {
        setLoading(false)
      }
    });
  };
  return { isLoading, filter, trackerData, fetchManpowerDetails}
}

export default useManpowerListHook