import React, { useEffect, useState } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default function EngineCkEditor({htmlData, onChangeData, onBlurData, disabled=false}){
  const [editorData, setEditorData] = useState(htmlData);
  useEffect(() => {
    if (htmlData !== editorData) {setEditorData(htmlData)}
  }, [htmlData])
  return(
    <div className="ck-content">
      <CKEditor
        editor={ ClassicEditor }
        data={editorData}
        disabled={disabled}
        config={{removePlugins:['MediaEmbed', 'ImageUpload', 'Indent']}}
        onInit={ editor => {}}
        onChange={ ( event, editor ) => {
            const data = editor.getData();
            if(onChangeData){onChangeData(data)}
        }}
        onBlur={ ( event, editor ) => {
          if(onBlurData){
            const data = editor.getData();
            onBlurData(data)
          }else{// event.preventDefault()
}}}
        onFocus={ ( event, editor ) => {
        }}
      />
    </div>
  )
}