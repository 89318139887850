import React from 'react'
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Grid,TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LocationField from './LocationField';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
const CustomFields = ({gridSize,fields,fieldData,error,onFieldChange}) => {
    const renderField = (field) => {
        const {
          type,
          name,
          label,
          options,
          regex,
          disabled,
          hidden,
          required,
          inputProps,
          defaultValue
        } = field;
        if (hidden) return null;
        if (type === "autocomplete") {
          const selectedValue =options?.length && fieldData && fieldData[name] ? options?.find((opt) =>  opt.id === fieldData[name]) : defaultValue? defaultValue :null ;
          return (
            <Autocomplete
              options={options || []}
              getOptionLabel={(option) => option?.name || ""}
              value={selectedValue}
              onChange={(e, selected) =>
                onFieldChange(name, selected ?  selected.id : null)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={required}
                  label={label}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={error && error[name]}
                  helperText={error && error[name] ? error[name] : ""}
                  InputLabelProps={{ shrink: true }}
                />
              )}
              disabled={disabled}
            />
          );
        }
        if (type === "date") {
          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                margin="normal"
                label={label}
                format={fieldDateFormat}
                fullWidth
                required={required}
                clearable={true}
                disableFuture
                value={fieldData && fieldData[name] || null}
                onChange={(date) =>onFieldChange(name, date ? moment(date).format(backendDateFormat) : null)}
                error={error && error[name]}
                helperText={error && error[name] || ""}
                inputVariant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          );
        }
        if(type==="googleField" && fieldData && Object.keys(fieldData).length>0 ){
          return( <LocationField location ={fieldData?.location ?fieldData?.location  : "" } onFieldChange={(e,key,value)=>onFieldChange(key,value)}  />)
        }
        return (
          <TextField
            name={name}
            label={label}
            fullWidth
            inputProps={inputProps}
            margin="normal"
            variant="outlined"
            value={fieldData && fieldData[name] === 0 ? 0 : fieldData[name]|| ""}
            onChange={(e) => {if (!regex || regex.test(e.target.value))onFieldChange(name, e.target.value);}}
            error={error && error[name]}
            helperText={error && error[name] || ""}
            required={required}
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
          />
        );
      };
  return (
    <> {fields
        .filter((field) => !field.hidden)
        .map((field, index) => (
            <Grid item md={gridSize} key={index}>
                {renderField(field)}
            </Grid>
        ))}</>
  )
}

export default CustomFields