import React, { useEffect, useState } from "react";
import { browserHistory } from "react-router";
import {ManPowerTracker,ProjectTracker,TrackerTab} from "../components";
import { onCheckPermission } from "../../../utils_v2";
import { STableLoader } from "../../../shared_elements/loaders";
import DeploymentMessage from "../../../shared/components/DeploymentMessage";
import LogoutInfo from "../../../shared/components/LogoutInfo";

const TrackerListing = (props) => {
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [tabIndex, setTabIndex] = useState(props?.location?.query?.tab ? props.location.query.tab :onCheckPermission("project_management", "tracker", "R")? "project": "manpower");
  useEffect(() => {props.location.query.tab ?setTabIndex(props.location.query.tab) :setTabIndex("project")}, [tabIndex]);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
      query: {
        ...location.query,
        tab: newValue,
      },
    };
    browserHistory.push(location);
  };
  
  return (
      <section className="projects-management-section">
        <DeploymentMessage />
        <LogoutInfo />
        <div className="projects-list-sec">
        {skeletonLoader ? (<STableLoader count={10} />) : (<TrackerTab tabIndex={tabIndex} handleTabChange={handleTabChange}/>)}
         {tabIndex === "project" ? (<ProjectTracker skeletonLoader={skeletonLoader} setSkeletonLoader={setSkeletonLoader} />) : (<ManPowerTracker skeletonLoader={skeletonLoader} setSkeletonLoader={setSkeletonLoader} />)}
        </div>
      </section>
  );
};
export default TrackerListing;
