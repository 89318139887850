import React, { useState } from 'react'
import { helperConst } from '..';
import { globalGetService } from '../../../globalServices';
import { checkApiStatus } from '../../../utils_v2';
import { useToastMessage } from '../../../shared_elements';

const useGetTrackerList = (apiUrl, setSkeletonLoader) => {
  const toastMessage = useToastMessage()
  const [isLoading, setLoading] = useState(false)
  const [trackerData, setTrackerData] = useState([])
  const [filter, setFilter] = useState({})
  const [hasMore, setHasMore] = useState(true);
  const getTrackerDetails = (query, loader, type,) => {
    if (loader === "skeletonLoader") {
      setSkeletonLoader(true)
    } else if (loader === "pageLoader") {
      setLoading(true)
    }
    globalGetService(apiUrl, query).then((response) => {
      const respMessage = response?.data?.message
      if (checkApiStatus(response)) {
        if (type === helperConst.EXPORT) {
          toastMessage(respMessage, "success")
        } else {
          delete query?.page;
          delete query?.per_page;
          setFilter(query)
          if (type === helperConst.INFINITE_LIST) {
            setTrackerData([...trackerData, ...response?.data?.data?.projects])
          } else {
            setTrackerData(response?.data?.data?.projects)
          }
          setHasMore(response?.data?.data?.projects?.length === response?.data?.data?.pagination?.per_page);
        }
      }
      if (loader === "skeletonLoader") {
        setSkeletonLoader(false)
      } else if (loader === "pageLoader") {
        setLoading(false)
      }
    });
  };
  return { isLoading, hasMore, filter, trackerData, getTrackerDetails, setTrackerData, }
}

export default useGetTrackerList