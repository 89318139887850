import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalBody, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ManTrackerSideInfo, MonthAndDateHeader, StatusDetails, UtilizationGraph } from '../components';
// import { sGlobalConstantAc } from '../../../../shared/actionCreators';
// import { ToolTipHover, ListLoader, NoRecordFound, ExportFiles } from '../';
// import { fetchManPowerTrackerAc, downLoadTrackerFormAc, fetchManPowerTrackerExpansionAc, fetchManPowerTrackerUtilizationAc } from '../actionCreators';
// import { imgStoragePath } from '../../../../constants';
import { UserInfoModal } from '../../../applications/technicalInspection/Elements';
// import showUlilIcon from '../../../../shared/assets/img/show_util.svg';
// import { onCheckPermission } from '../../../../utils_v2';
import { globalGetService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus,onCheckPermission,getLocalStorageInfo,removeEmptyKey , } from '../../../utils_v2';
import { TableFilter } from '../../../shared';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';

const ManPowerTrackers = () => {

  return (
    <s className='projects-management-section'>
  

        <InfiniteScroll
        //   dataLength={manPowerTrackerDtls.pagination ? manPowerTrackerDtls.pagination.total : 0}
        //   next={moveNextPage}
        //   hasMore={manPowerTrackerDtls.pagination && manPowerTrackerDtls.pagination.total > manPowerTrackerDtls.pagination.page_count}
        //   loader={<ListLoader />}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Utilization</TableCell>
                  <TableCell>Join Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {manPowerTrackerDtls.data && manPowerTrackerDtls.data.length > 0 ? ( */}
                  {/* manPowerTrackerDtls.data.map((row, index) => ( */}
                    <TableRow key={'index'}>
                      <TableCell>{'row.name'}</TableCell>
                      <TableCell>{'row.department'}</TableCell>
                      <TableCell>{'row.location'}</TableCell>
                      <TableCell>{'row.project'}</TableCell>
                      <TableCell>{'row.status'}</TableCell>
                      <TableCell>
                        <UtilizationGraph utilization={'row.utilization'} />
                      </TableCell>
                      <TableCell>{moment('row.join_date').format('MM/DD/YYYY')}</TableCell>
                    </TableRow>
                  {/* ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">No records found</TableCell>
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
        
        {/* <UserInfoModal 
          showModal={userDetail.flag} 
          toggleModal={toggle} 
          user={userDetail.data}
        /> */}
    </s>
  );
};

export default ManPowerTrackers;
