import React from 'react';
import { TablePagination } from '@material-ui/core';
const Pagination = ({pagination, onChangePage, onChangeRowsPerPage, stacks=[10, 20, 50, 100, 200]}) => {

    const page= pagination?.current_page ? pagination?.current_page : pagination?.page
    return(
        pagination && Object.keys(pagination).length && pagination.total > 0 ?
            <TablePagination
              rowsPerPageOptions={stacks}
              component="div"
              count={pagination.total}
              rowsPerPage={pagination?.per_page}
              page={page - 1}
              backIconButtonProps={{'aria-label': 'previous page'}}
              nextIconButtonProps={{'aria-label': 'next page'}}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />:
        null
    )
}
export default Pagination