import moment from "moment";
import { getLocalStorageInfo } from "../../utils_v2";

export const helperFunc = {
    getChartData: function (utilizationData) {
        if (utilizationData) {
            let data = [];
            let categories = [];
            let year = moment(utilizationData?.start_date, "YYYY-MM-DD").year();
            for (let i = 1; i < 13; i++) {
                if (utilizationData?.utilizations && utilizationData?.utilizations[i] == null) {
                    categories.push(moment(new Date(year + "/" + i + "/01")).format('MMM YYYY'));
                    data.push(0);
                } else {
                    categories.push(moment(year + "/" + i + "/01").format('MMM YYYY'));
                    data.push(parseFloat((utilizationData?.utilizations && utilizationData?.utilizations[i].percentage).toFixed(2)));
                }
            }
            return { data, categories }
        }
    },
    checkSecurityGroup: function () {
        let obj = null;
        obj = getLocalStorageInfo().user.security_groups.find(g => g.id == 24);
        if (obj) {return true;} else {return false;}
    }
}

