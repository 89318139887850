import React from "react";
import {  Grid, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { editorDataArr } from ".."
import EngineCkEditor from "../shared/EngineCkEditor";
import CustomFields from "../shared/CustomFields";
import { regexConstants } from "../../../constants/regEx";
import { PageLoader } from "../../../shared_elements";
const EngineWorkOrderDetail = ({ engine, onFieldChange,params,  error, enginesList, engineTypes, aircraftTypes, pltConstants ,isLoading}) => {
  const fields = [
        {
          type: "autocomplete",
          name: "engine_type_id",
          label: "Engine Type",
          options: engineTypes,
          required:true,
          disabled: !engine?.create_esn,
        },
        {
          type: "date",
          name: "engine_manufacturing_date",
          label: "Manufacturing Date",
          required:true,
        },
        {
            type: "text",
            name: "country",
            label: "Country",
            required : true,
            hidden : !engine?.create_esn
          } ,
        {
          type: "text",
          name: "tsn",
          label: "TSN",
          required : true,
          regex: regexConstants.numberWithDot,
          inputProps:{ maxLength: 10}
        },
        {
          type: "text",
          name: "csn",
          label: "CSN",
          required:true,
          regex: regexConstants.onlyNumeric,
          inputProps:{ maxLength: 10}
        },
        {
            type: "text",
            name: "aircraft_msn",
            label: "A/C Serial Number",
          },
          {
            type: "text",
            name: "aircraft_registration",
            label: "A/C Registration",
          },
        {
          type: "autocomplete",
          name: "aircraft_type_id",
          label: "A/C Model",
          options: aircraftTypes,
        },
        {
            type: "autocomplete",
            name: "engine_status",
            label: "Status",
            options: [{ name: 'On Wing', id: 1 }, { name: 'In Shop', id: 2 }, { name: 'In Storage', id: 3 }],
            defaultValue : { name: 'On Wing', id: 1 }
          },
        {
            type: "autocomplete",
            name: "owned_by_type",
            label: "Ownership",
            options:  [{ name: 'Owner', id: 1 }, { name: 'Client', id: 2 }],
            defaultValue : { name: 'Owner', id: 1 }
        },
        {
          type: "googleField",
          name: "location",
      },
        {
          type: "autocomplete",
          name: "install_position",
          label: "Installed Position",
          options: pltConstants?.filter((item) => item.type === "engine_position"),
          defaultValue : {name : "LH-1",value : 1}
        },
        {
          type: "text",
          name: "work_order_number",
          label: "Work Order Number",
        },
        {
          type: "text",
          name: "operated_by",
          label: "Engine Operated By",
        },
        {
          type: "text",
          name: "constructed_model",
          label: "Constructed Model",
        },
        {
            type: "date",
            name: "date_of_installation",
            label: "Date of Installation",
          },
        {
            type: "text",
            name: "tso",
            label: "TSO",
            regex: regexConstants.numberWithDot,
            inputProps:{ maxLength: 10}
        },
        {
            type: "text",
            name: "cso",
            label: "CSO",
            regex: regexConstants.onlyNumeric,
            inputProps:{ maxLength: 10}
        },
        {
            type: "text",
            name: "tslv",
            label: "TSLV",
            regex: regexConstants.numberWithDot,
            inputProps:{ maxLength: 10}
        },
        {
            type: "text",
            name: "cslv",
            label: "CSLV",
            regex: regexConstants.onlyNumeric,
            inputProps:{ maxLength: 10}
        },
        {
            type: "text",
            name: "tshi",
            label: "TSHSI",
            regex: regexConstants.numberWithDot,
            inputProps:{ maxLength: 10}
        },
        {
            type: "text",
            name: "cshi",
            label: "CSHSI",
            regex: regexConstants.onlyNumeric,
            inputProps:{ maxLength: 10}
        },
        {
            type: "text",
            name: "tsr",
            label: "TSR",
            regex: regexConstants.numberWithDot,
            inputProps:{ maxLength: 10}
        },
        {
            type: "text",
            name: "csr",
            label: "CSR",
            regex: regexConstants.onlyNumeric,
            inputProps:{ maxLength: 10}
        },
        {
            type: "text",
            name: "mpa_test_margin",
            label: "MPA Test Margin",
        },
        {
            type: "text",
            name: "llp_status_sheet",
            label: "LLP Status",
        },
        {
            type: "text",
            name: "ad_status",
            label: "AD Status",
        },
        {
            type: "text",
            name: "sb_status",
            label: "SB Status",
        },
        {
            type: "text",
            name: "lease_return_standard",
            label: "Engine Lease Return Condition",
        },
        {
            type: "text",
            name: "etops",
            label: "ETOPS",
        },
      ]
    return (
        <div className="project-management-modal">
            <div style={{ maxHeight: params?.workOrderSlug ? (window.innerHeight -266) + 'px' :  (window.innerHeight - 312) + 'px', overflow: 'auto', padding: '20px', position: '', marginLeft: '0px' }}>
                <Grid container spacing={1}>
                    <Grid item md={2}>
                        {engine?.create_esn ?
                            <TextField
                                required
                                name="esn"
                                label="Engine Serial Number"
                                fullWidth
                                margin="normal"
                                value={engine?.esn || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange('esn', e.target.value)}
                                error={error?.esn}
                                helperText={error?.esn || ''}
                                variant='outlined'
                            /> :
                            <> {(() => {
                                let optionValue = enginesList?.length && engine?.engine_id ? enginesList.find(item => item.id === engine?.engine_id) : null
                                return (
                                    <Autocomplete
                                        disabled={params?.workOrderSlug}
                                        options={enginesList}
                                        getOptionLabel={option => option.esn}
                                        name="engine_id"
                                        value={optionValue}
                                        onChange={(e, value) => onFieldChange('engine_id', value ?  value : null)}
                                        renderInput={params => <TextField required error={error?.esn} helperText={error?.esn || ''} {...params} label="Engine Serial Number" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                                )
                            })()}</>
                        }
                    </Grid>
                    <CustomFields gridSize={2}  fields={fields} fieldData={engine} error={error} onFieldChange={onFieldChange} />
                    {editorDataArr?.map((editorData,index)=>{
                        return( <Grid item key={index+editorData.label} md={12}>
                            <p className="editor-label">{editorData?.label}</p>
                            {engine && Object.keys(engine)?.length>0 &&<EngineCkEditor
                             htmlData={engine && engine[editorData.value] || ""}
                             onChangeData={(data) => onFieldChange(editorData?.value, data)}
                            /> }
                        </Grid>)
                    })}
                </Grid>
            </div>
            {isLoading && <PageLoader />}
        </div>
    )
}
export default EngineWorkOrderDetail;